import {useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {NEW_PARASITE_TRACKER_MAP_CODENAME} from '@/_new-code/products/disease-map/parasite-tracker/new-parasite-map/new-parasite-map.mac'
import type {NewParasiteTrackerMapContentItem} from '@/_new-code/products/disease-map/parasite-tracker/new-parasite-map'
import {ParasiteTrackerMap as NewParasiteTrackerMap} from '@/_new-code/products/disease-map/parasite-tracker/new-parasite-map'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {ParasiteTrackerSidebar as NewParasiteTrackerSidebar} from '@/_new-code/products/disease-map/parasite-tracker/new-parasite-map/new-sidebar'
import {logError} from '@/services/client-logger'
import type {ParasiteTrackerMapContentItem} from '../parasite-tracker-map'
import {ParasiteTrackerMapBlock} from '../parasite-tracker-map'
import type {ParasiteTrackerSidebarContentItem} from '../parasite-tracker-sidebar'
import {ParasiteTrackerSidebarBlock} from '../parasite-tracker-sidebar'

export type ParasiteTrackerModuleContentItem = IContentItem<{
	parasiteTrackerMap: Elements.LinkedItemsElement<
		NewParasiteTrackerMapContentItem | ParasiteTrackerMapContentItem
	>
	parasiteTrackerSidePanel: Elements.LinkedItemsElement<ParasiteTrackerSidebarContentItem>
}>

export interface LatLng {
	lat: number
	lng: number
}

export const ParasiteTrackerModuleBlock: Block<
	ParasiteTrackerModuleContentItem
> = ({
	block: {
		elements: {
			parasiteTrackerMap: [mapBlock],
			parasiteTrackerSidePanel: [sidePanelBlock],
		},
	},
	...context
}) => {
	const [searchedCasesData, setSearchedCasesData] = useState<
		unknown[] | null
	>(null)
	const [searchedLocation, setSearchedLocation] = useState<LatLng | null>(
		null
	)
	const [error, setError] = useState(false)

	const [showSummaryText, setShowSummaryText] = useState<boolean | undefined>(
		false
	)
	const [reportedCases, setReportedCases] = useState<string>('')
	const [locationName, setLocationName] =
		useState<string>('the selected area')

	// eslint-disable-next-line react/jsx-no-useless-fragment -- remove when react types are fixed and can return null
	if (!(mapBlock && sidePanelBlock)) return <></>

	const handleSearchApi = async (location: LatLng): Promise<void> => {
		try {
			const {lat, lng} = location
			const res = await fetch(
				`/api/disease/search?lat=${lat}&lon=${lng}&disease=${mapBlock
					.elements.parasiteType[0]?.codename}&startDate=${
					mapBlock.elements.startDate ?? ''
				}`
			)

			if (!res.ok)
				logError(
					`Searching disease API failed with status code: ${res.status}`
				)

			throw Error(
				`Searching disease API failed with status code: ${res.status}`
			)

			const data = (await res.json()) as unknown[]
			setSearchedLocation(location)
			setSearchedCasesData(data)
		} catch (e) {
			logError(e as Error)
			setError(true)
		}
	}

	const Map =
		mapBlock.system.type === NEW_PARASITE_TRACKER_MAP_CODENAME
			? NewParasiteTrackerMap
			: ParasiteTrackerMapBlock

	const Sidebar =
		mapBlock.system.type === NEW_PARASITE_TRACKER_MAP_CODENAME
			? NewParasiteTrackerSidebar
			: ParasiteTrackerSidebarBlock

	return (
		<div className="flex flex-col md:flex-row">
			<div className="w-full md:w-2/3 md:flex-1">
				<Map
					// @ts-expect-error -- This is fine, we're just missing the type gymnastics to make it work
					block={mapBlock}
					error={error}
					locationName={locationName}
					reportedCases={reportedCases}
					searchApi={handleSearchApi}
					searchedCases={searchedCasesData}
					searchedLocation={searchedLocation}
					setError={setError}
					setLocationName={setLocationName}
					setReportedCases={setReportedCases}
					setShowSummaryText={setShowSummaryText}
					showSummaryText={showSummaryText}
					theme={sidePanelBlock.elements.themeHexcode || '#2772ce'}
					{...context}
				/>
			</div>
			<div className="w-full md:w-1/3">
				<Sidebar
					block={{
						...sidePanelBlock,
					}}
					locationName={locationName}
					parasiteType={mapBlock.elements.parasiteType}
					reportedCases={reportedCases}
					searchedCases={searchedCasesData}
					setLocationName={setLocationName}
					setReportedCases={setReportedCases}
					setShowSummaryText={setShowSummaryText}
					showSummaryText={showSummaryText}
					{...context}
				/>
			</div>
		</div>
	)
}
