import {useState, useEffect, createRef} from 'react'
import {Icon} from '@elanco/component-library-v2'
import {clsx} from 'clsx'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

export interface NavItemsProps {
	id: string
	title: string
}

interface ExtraLinksProps {
	url: string
	text: string
	iconBrand: string
	svg: React.ReactNode
}

interface InPageNavProps {
	containerId: string
	navOffset?: number
	navItems?: NavItemsProps[]
	extraLinks?: ExtraLinksProps[]
	closeNavAriaLabel?: string
	openNavAriaLabel?: string
	storageKey?: string
	hideOnMobile?: boolean
	showDropdownOnMobile?: boolean
	isPositionWithinParent?: boolean
}

export const InPageNav: React.FC<InPageNavProps> = ({
	containerId = '',
	navOffset = 0,
	navItems = [],
	extraLinks = [],
	closeNavAriaLabel = 'Close navigation',
	openNavAriaLabel = 'Open navigation',
	storageKey = 'show-in-page-nav',
	hideOnMobile = false,
	showDropdownOnMobile = false,
	isPositionWithinParent = false,
}) => {
	const isClient = typeof window === 'object'
	const {isMobile} = useWindowSize()
	const isDropdownShown = isMobile && showDropdownOnMobile
	const extraOffset = 60 // to make sure it doesn't scroll to top edge of section
	let navTimer: NodeJS.Timeout
	const bounceNavStorageKey = 'bounce-in-page-nav'
	const nav = createRef<HTMLUListElement>()
	const [showStickyCTAs, setShowStickyCTAs] = useState(false)
	const [bounceInPageNav, setBounceInPageNav] = useState(true)
	const [stickyElementWidth, setStickyElementWidth] = useState(0)
	const [activeNavIndex, setActiveNavIndex] = useState(
		showDropdownOnMobile ? 0 : -1
	)
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	let hasEventsInit = false
	let stickyNavHeight = 0

	const setNavPosition = (index: number): void => {
		if (!isMobile) {
			const navParentEl = nav.current?.parentElement
			const li = navParentEl?.querySelectorAll('li')[index]
			if (li) navParentEl.scrollTop = li.offsetTop
		}
	}

	const setActiveSection = (scrollTopPosition: number): void => {
		let activeIndex = showDropdownOnMobile ? 0 : -1
		const navParentEl = nav.current?.parentElement
		const navParentElHeight = navParentEl?.offsetHeight

		navItems.forEach((navItem, i) => {
			const sectionEl = document.querySelector<HTMLElement>(
				`#${navItem.id}`
			)

			if (!sectionEl || !navParentElHeight) return

			const sectionTopPos =
				sectionEl.getBoundingClientRect().top +
				scrollTopPosition -
				(isDropdownShown && i !== 0 ? navParentElHeight : 0) -
				(isDropdownShown ? navOffset : navOffset + extraOffset)

			const sectionBottomPos =
				sectionTopPos + navOffset + sectionEl.offsetHeight

			if (
				scrollTopPosition >= sectionTopPos &&
				scrollTopPosition < sectionBottomPos
			) {
				activeIndex = i
			}
		})

		if (activeIndex >= 0) {
			setNavPosition(activeIndex)
		}

		setActiveNavIndex(activeIndex)
	}

	const handleScroll = (): void => {
		if (!isClient || !containerId || (isMobile && hideOnMobile)) return

		const containerEl = document.querySelector<HTMLElement>(
			`#${containerId}`
		)

		const navEl = nav.current
		if (!containerEl || !navEl) return

		const navParentEl = navEl.parentElement
		if (!navParentEl) return

		const scrollTopPosition =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop

		const stickyNav = document.querySelector<HTMLElement>('#sticky-nav')
		if (stickyNav) stickyNavHeight = stickyNav.offsetHeight

		const navParentElHeight = navParentEl.offsetHeight
		const containerTopPos =
			containerEl.getBoundingClientRect().top +
			scrollTopPosition -
			(isDropdownShown ? navParentElHeight : navOffset + extraOffset)
		const containerBottomPos =
			containerTopPos + containerEl.offsetHeight - navParentElHeight
		if (
			scrollTopPosition >= containerTopPos &&
			scrollTopPosition < containerBottomPos
		) {
			navParentEl.classList.add('fixed')
			navParentEl.style.top = `${stickyNavHeight}px`
		} else if (scrollTopPosition >= containerBottomPos) {
			navParentEl.classList.remove('fixed')
			navParentEl.style.top = `${containerBottomPos - containerTopPos}px`
		} else {
			navParentEl.classList.remove(isPositionWithinParent ? 'fixed' : '')
			navParentEl.style.top = isPositionWithinParent
				? ''
				: `${stickyNavHeight}px`
		}
		setActiveSection(scrollTopPosition)
	}

	const scrollToSection = (section: HTMLElement): void => {
		if (!isClient) {
			return
		}
		const navParentEl = nav.current?.parentElement
		const scrollTopPosition =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop

		const navParentElHeight = navParentEl?.offsetHeight
		if (!navParentElHeight) return

		const topOfElement =
			section.getBoundingClientRect().top +
			scrollTopPosition -
			(isDropdownShown
				? navParentElHeight + navOffset
				: navOffset + extraOffset)

		window.scroll({top: topOfElement + 5, behavior: 'smooth'})
	}

	const insideNavEvent = (): void => {
		clearTimeout(navTimer)
	}

	const outsideNavEvent = (): void => {
		navTimer = setTimeout(() => {
			const navEl = nav.current
			if (navEl === null || !isMobile) return

			if (!navEl.parentElement?.contains(document.activeElement))
				setIsDropdownOpen(false)
		}, 0)
	}

	const navClick = (id: string): void => {
		if (isDropdownShown) {
			setIsDropdownOpen(!isDropdownOpen)
			if (!isDropdownOpen) return
		}

		const section = document.querySelector<HTMLElement>(`#${id}`)
		if (!section) return

		scrollToSection(section)
	}

	const stopBouncingPageNav = (): void => {
		if (bounceInPageNav) {
			setBounceInPageNav(false)
			localStorage.setItem(bounceNavStorageKey, JSON.stringify(false))
		}
	}

	const toggleNotification = (): void => {
		setShowStickyCTAs(!showStickyCTAs)
		localStorage.setItem(storageKey, JSON.stringify(!showStickyCTAs))
		stopBouncingPageNav()
	}

	const toggleNotificationOnKeyUp = (
		e: React.KeyboardEvent<HTMLButtonElement>
	): void => {
		if (e.key === 'Enter') {
			toggleNotification()
			stopBouncingPageNav()
		}
	}

	const handleResize = (): void => {
		const navEl = nav.current
		const navParentEl = navEl?.parentElement
		if (!navParentEl) return

		const removeEvents = (): void => {
			hasEventsInit = false
			window.removeEventListener('scroll', handleScroll)
			if (showDropdownOnMobile) {
				navParentEl.removeEventListener('focus', insideNavEvent, true)
				navParentEl.removeEventListener('blur', outsideNavEvent, true)
			}
		}

		const addEvents = (): void => {
			hasEventsInit = true
			window.addEventListener('scroll', handleScroll)
			if (showDropdownOnMobile) {
				navParentEl.addEventListener('focus', insideNavEvent, true)
				navParentEl.addEventListener('blur', outsideNavEvent, true)
			}
		}

		if (isMobile && hideOnMobile && hasEventsInit) {
			// remove the events on mobile if we don't need them
			removeEvents()
		} else if (!isMobile && hideOnMobile && !hasEventsInit) {
			addEvents()
		} else if (!hideOnMobile && !hasEventsInit) {
			addEvents()
		}
	}

	useEffect(() => {
		if (!isClient) return

		const navEl = nav.current
		const navParentEl = navEl?.parentElement
		if (!navParentEl) return

		const stickyNav = document.querySelector<HTMLElement>('#sticky-nav')
		navParentEl.style.top = isPositionWithinParent
			? ''
			: `${stickyNav?.offsetHeight}px`

		setStickyElementWidth(navEl.offsetWidth)
		if (localStorage.getItem(storageKey) === null) {
			localStorage.setItem(storageKey, JSON.stringify(false))
		}

		if (localStorage.getItem(bounceNavStorageKey) === null) {
			localStorage.setItem(bounceNavStorageKey, JSON.stringify(true))
		}

		const storageValue = localStorage.getItem(storageKey)
		if (storageValue) {
			const lsValue = Boolean(JSON.parse(storageValue))
			if (showStickyCTAs !== lsValue) {
				setShowStickyCTAs(lsValue)
			}
		}

		const bounceValue = localStorage.getItem(bounceNavStorageKey)
		if (bounceValue) {
			const bounceKeyValue = Boolean(JSON.parse(bounceValue))
			if (!bounceKeyValue) {
				setBounceInPageNav(bounceKeyValue)
			}
		}

		handleResize()
		window.addEventListener('resize', handleResize)
		window.addEventListener('scroll', () => setTimeout(handleScroll, 500))

		return () => {
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('scroll', handleScroll)
			navParentEl.removeEventListener('focus', insideNavEvent, true)
			navParentEl.removeEventListener('blur', outsideNavEvent, true)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps -- Should only run once
	}, [])

	return (
		<div
			className={clsx(
				hideOnMobile && 'hidden md:block',
				showDropdownOnMobile && 'md:block'
			)}
		>
			<div
				className={clsx(
					'transition-left absolute left-0 top-0 z-30 max-h-none w-full duration-200 ease-in-out md:flex',
					!isPositionWithinParent && '!fixed'
				)}
				style={{
					maxHeight: `calc(100vh - ${navOffset}px)`,
					left:
						showStickyCTAs || isDropdownShown
							? '0'
							: `-${stickyElementWidth}px`,
				}}
			>
				<ul
					className={clsx(
						"max-h-screen w-auto overflow-y-auto before:absolute before:right-0 before:top-0 before:z-30 before:ml-2 before:mr-4 before:mt-6 before:inline-block before:h-[6px] before:w-[14px] before:bg-dropdown before:bg-no-repeat before:content-[''] md:before:hidden",
						isDropdownOpen && 'before:rotate-180'
					)}
					ref={nav}
				>
					{navItems.map((child, i) => (
						<li
							className={clsx(
								isDropdownShown &&
									!isDropdownOpen &&
									activeNavIndex !== i &&
									'hidden'
							)}
							key={child.title}
						>
							<button
								className={clsx(
									"relative block w-full border-2 border-b-2 border-gray-500 bg-white px-4 py-3 text-left before:absolute before:left-0 before:top-0 before:h-full before:w-2 before:bg-[#eee8df] before:transition before:duration-[400] before:content-[''] md:w-[160px] md:border-l-0 md:border-r-0 md:border-t-0",
									((isDropdownShown && !isDropdownOpen) ||
										activeNavIndex !== i) &&
										'before:hidden'
								)}
								onClick={() => {
									navClick(child.id)
								}}
								type="button"
							>
								{child.title}
							</button>
						</li>
					))}
					{extraLinks.map((child, i) => (
						// eslint-disable-next-line react/no-array-index-key -- List is the same for every render
						<li key={i}>
							<a
								className="relative flex w-[160px] items-center border-b-2 border-gray-400 bg-gray-400 px-4 py-3 text-left before:hidden"
								href={child.url}
								rel="noopener noreferrer"
								target="_blank"
							>
								<span className="pr-2">{child.text}</span>
								<Icon
									brand={child.iconBrand}
									className="ml-auto shrink-0"
									size="xs"
								>
									{child.svg}
								</Icon>
							</a>
						</li>
					))}
				</ul>
				<div>
					<button
						aria-label={
							showStickyCTAs
								? closeNavAriaLabel
								: openNavAriaLabel
						}
						className={clsx(
							'mt-2 hidden cursor-pointer bg-gray-400 hover:bg-gray-300 md:block',
							bounceInPageNav &&
								!showStickyCTAs &&
								'animate-bounce'
						)}
						onClick={toggleNotification}
						onKeyUp={toggleNotificationOnKeyUp}
						tabIndex={0}
						title={
							showStickyCTAs
								? closeNavAriaLabel
								: openNavAriaLabel
						}
						type="button"
					>
						{showStickyCTAs ? (
							<svg
								className="h-8 w-8 fill-current p-2"
								viewBox="0 0 15.7 15.7"
							>
								<path d="M14.3,0.3l1.1,1.1c0.4,0.4,0.4,1,0,1.4L2.8,15.4c-0.4,0.4-1,0.4-1.4,0l-1.1-1.1c-0.4-0.4-0.4-1,0-1.4L12.9,0.3C13.3-0.1,13.9-0.1,14.3,0.3z" />
								<path d="M15.4,14.3l-1.1,1.1c-0.4,0.4-1,0.4-1.4,0L0.3,2.8c-0.4-0.4-0.4-1,0-1.4l1.1-1.1c0.4-0.4,1-0.4,1.4,0l12.6,12.6C15.8,13.3,15.8,13.9,15.4,14.3z" />
							</svg>
						) : (
							<svg
								className="h-10 w-8 px-2 py-2"
								viewBox="0 0 16 24"
							>
								<g transform="translate(-5 -4)">
									<path
										d="M10.5,15l8,8,8-8Z"
										fill="#02253e"
										transform="translate(-5.5 5)"
									/>
									<path
										d="M10.5,23l8-8,8,8Z"
										fill="#02253e"
										transform="translate(-5.5 -11)"
									/>
								</g>
							</svg>
						)}
					</button>
				</div>
			</div>
		</div>
	)
}
