import React, {useEffect, useRef} from 'react'
import {Helmet} from 'react-helmet'
import {clsx} from 'clsx'
import {pushToDataLayer} from '@/utils/analytics'

interface WistiaProps {
	alt?: string
	autoPlay?: boolean
	className?: string
	id: string
	componentSpacing?: string
	spacing?: {
		default: string
		top: string
		bottom: string
		none: string
	}
	wistiaPodcast?: {choice: string}
}

interface WistiaVideoObject {
	time: () => number
	duration: () => number
	bind: (name: string, callback: (event: Event) => void) => void
}

interface WistiaQueueItem {
	id: string
	onReady: (vid: WistiaVideoObject) => void
}

const WistiaVideoDefault: WistiaVideoObject = {
	time: () => 0,
	duration: () => 0,
	bind: () => undefined,
}

declare global {
	interface Window {
		_wq: WistiaQueueItem[]
	}
}

export const Wistia = ({
	alt = '',
	autoPlay = false,
	className = '',
	id,
	componentSpacing,
	spacing,
	wistiaPodcast,
}: WistiaProps): JSX.Element => {
	const videoRef = useRef(WistiaVideoDefault)
	const progressTargetsRef = useRef([10, 25, 50, 75])
	const startLogged = useRef(false)
	const endLogged = useRef(false)

	const onPlayHandler = (): void => {
		if (!startLogged.current) {
			pushToDataLayer({
				event: 'video_start',
				video_title: `${alt || 'No alt text set'} (wistia id: ${id})`,
				video_url: `https://elancoanimalhealth.wistia.com/medias/${id}`,
			})
			startLogged.current = true
		}
	}

	const onEndHandler = (): void => {
		if (!endLogged.current) {
			pushToDataLayer({
				event: 'video_complete',
				video_percent: '100%',
				video_title: `${alt} (wistia id: ${id})`,
				video_url: `https://elancoanimalhealth.wistia.com/medias/${id}`,
				video_duration: videoRef.current.duration(),
			})
			endLogged.current = true
		}
	}

	const onReady = (vid: WistiaVideoObject): void => {
		videoRef.current = vid
		vid.bind('play', onPlayHandler)
		vid.bind('end', onEndHandler)
	}

	useEffect(() => {
		window._wq = []
		window._wq.push({
			id,
			onReady,
		})
	}, [])

	const handlePollingTick = (): void => {
		const vid = videoRef.current
		const watchedPercentage = (vid.time() / vid.duration()) * 100
		const numberOfTargets = progressTargetsRef.current.length

		const progressTargets = [...progressTargetsRef.current].filter(
			(targetPercentage) => {
				const hit = watchedPercentage >= targetPercentage
				if (hit) {
					pushToDataLayer({
						event: 'video_progress',
						video_percent: `${targetPercentage}%`,
						video_title: `${alt} (wistia id: ${id})`,
						video_url: `https://elancoanimalhealth.wistia.com/medias/${id}`,
						video_duration: vid.duration(),
					})
				}
				return !hit
			}
		)
		const progressMade = progressTargets.length < numberOfTargets
		if (progressMade) progressTargetsRef.current = progressTargets
	}

	useEffect(() => {
		const interval = setInterval(handlePollingTick, 2000)
		return () => {
			clearInterval(interval)
		}
	}, [])

	const iframeHeight = wistiaPodcast?.choice === 'podcast' ? '218px' : '100%'
	return (
		<>
			<Helmet>
				<script async src="//fast.wistia.net/assets/external/E-v1.js" />
			</Helmet>
			<div
				className={clsx(
					className,
					componentSpacing !== spacing?.none
						? 'container-wide'
						: 'w-full',
					'flex justify-center rounded-md py-4'
				)}
			>
				<div
					className={clsx(
						wistiaPodcast?.choice === 'podcast'
							? 'px-0 pb-0 pt-[20%]'
							: 'px-0 pb-0 pt-[52%]',
						'wistia_responsive_padding relative w-[inherit]'
					)}
				>
					<div className="wistia_responsive_wrapper absolute left-0 top-0 h-full w-full">
						<iframe
							allow="autoplay; fullscreen"
							allowFullScreen
							className="wistia_embed"
							frameBorder="0"
							height={iframeHeight}
							name="wistia_embed"
							scrolling="no"
							src={`https://fast.wistia.net/embed/iframe/${id}?videoFoam=true&autoPlay=${autoPlay}`}
							title={alt}
							width="100%"
						/>
					</div>
				</div>
			</div>
		</>
	)
}
