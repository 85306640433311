import {Button, ButtonIcons} from '@elanco/component-library-v2'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {
	mapLinksWithLocale,
	addLocalePrefix,
	filterLinksByAppPermissions,
} from '@/components/BlockMapper/utils'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {pushToDataLayer} from '@/utils/analytics'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	MyAccountWrapperContentItem,
	NavItemLinkContentItem,
} from '@/_new-code/products/flexible-web-toolkit/components/header/models'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {AccountDropdown} from '@/_new-code/products/flexible-web-toolkit/components/header/primary/account-dropdown'

interface PrimaryButtonsProps {
	buttonLinks:
		| Tersed<NavItemLinkContentItem>[]
		| Tersed<CtaButtonContentItem>[]
	variant?: string
	className?: string
	loginUrl: string
	myAccountWrapper?: Tersed<MyAccountWrapperContentItem>
}

export const PrimaryButtons = (props: PrimaryButtonsProps): JSX.Element => {
	const authState = useAuth()
	const {locale = ''} = useRouter()

	const filteredLinks = filterLinksByAppPermissions(
		props.myAccountWrapper?.elements.accountNavLinks ?? [],
		authState
	)

	const myAccountLinksWithLocale = mapLinksWithLocale(
		filteredLinks[0]?.sublinks ?? [],
		locale
	)

	if (authState.authenticated) {
		return (
			<>
				{props.myAccountWrapper ? (
					<AccountDropdown
						accountIcon={
							props.myAccountWrapper.elements.accountIcon
						}
						greetingText={
							props.myAccountWrapper.elements.greetingText
						}
						navLinks={myAccountLinksWithLocale}
					/>
				) : null}
			</>
		)
	}

	return (
		<>
			{props.buttonLinks.length > 0 &&
				props.buttonLinks.map((btn) => (
					<div
						className="flex-1 md:flex-none"
						data-kontent-item-id={btn.system.id}
						key={btn.system.id}
					>
						<Link
							href={addLocalePrefix(btn.elements.url, locale)}
							passHref
						>
							<Button
								as="a"
								className="w-full text-sm"
								data-kontent-element-codename="text"
								icon={
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: fix component library types
									btn.elements.url.includes('http')
										? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
											ButtonIcons.External
										: undefined
								}
								onClick={() => {
									pushToDataLayer({
										event: 'cta_click',
										cta_name: btn.elements.text,
										cta_category: 'CTA_Button',
									})
								}}
								padding={props.className ?? 'py-1 px-6'}
								variant={props.variant ?? 'primary'}
							>
								{btn.elements.text}
							</Button>
						</Link>
					</div>
				))}
		</>
	)
}
