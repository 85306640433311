import {useState} from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type JotFormContentItem = IContentItem<{
	tabTitle: Elements.TextElement
	jotformId: Elements.TextElement
	height: Elements.TextElement
}>

export const JotFormBlock: Block<JotFormContentItem> = ({block}) => {
	const [isLoaded, setIsLoaded] = useState(false)
	return (
		<div>
			{!isLoaded && (
				<div className="flex w-full justify-center py-6">
					<ScaleLoader
						color="#02253e"
						height={40}
						loading
						margin={5}
						width={4}
					/>
				</div>
			)}
			<iframe
				allow="geolocation; microphone; camera"
				allowFullScreen
				className={clsx(!isLoaded && 'hidden')}
				frameBorder="0"
				id={`JotFormIFrame-${block.elements.jotformId}`}
				onLoad={() => {
					setIsLoaded(true)
				}}
				src={`https://elancoah.jotform.com/${block.elements.jotformId}`}
				style={{
					minWidth: '100%',
					maxHeight: '100%',
					height: block.elements.height || '800px',
					border: 'none',
					overflow: 'scroll',
				}}
				title={`JotForm IFrame ${block.elements.jotformId}`}
			/>
		</div>
	)
}
